import GlobalStyles from "./styles/GlobalStyles";

import { Routes } from "./routes";

export default function App() {
    return (
        <>
            <GlobalStyles/>
            <Routes/>
        </>
    );
};