import { Header } from "./style";

export function HeaderMenu() {
    return (
        <Header>
            <div className="profile">
                <h1 className="title">Loki<strong>Flix</strong></h1>
            </div>
        </Header>
    );
};